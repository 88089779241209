// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-overview-index-js": () => import("./../src/pages/overview/index.js" /* webpackChunkName: "component---src-pages-overview-index-js" */),
  "component---src-pages-search-index-js": () => import("./../src/pages/search/index.js" /* webpackChunkName: "component---src-pages-search-index-js" */),
  "component---src-pages-signin-index-js": () => import("./../src/pages/signin/index.js" /* webpackChunkName: "component---src-pages-signin-index-js" */),
  "component---src-pages-terms-index-js": () => import("./../src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-templates-column-page-js": () => import("./../src/templates/column-page.js" /* webpackChunkName: "component---src-templates-column-page-js" */),
  "component---src-templates-fortune-page-js": () => import("./../src/templates/fortune-page.js" /* webpackChunkName: "component---src-templates-fortune-page-js" */),
  "component---src-templates-fortune-post-js": () => import("./../src/templates/fortune-post.js" /* webpackChunkName: "component---src-templates-fortune-post-js" */),
  "component---src-templates-selected-page-js": () => import("./../src/templates/selected-page.js" /* webpackChunkName: "component---src-templates-selected-page-js" */)
}

